import React, { useState } from "react";
import Hero from "../components/Hero";
import { Link } from "react-router-dom";
import ContactDetails from "../components/ContactDetails";
import Breadcrumb from "../components/Breadcrumb";
import WebPageTitle from "../components/WebPageTitle";
import ContactForm from "../components/ContactForm";

function Contact() {
  return (
    <div>
      <WebPageTitle title="Contact us" /> {/*page title */}
      <Breadcrumb pageTitle={"Contact us"}/>
      <div className="container-xxl py-5">
        <div className="container px-lg-5">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div
                className="section-title position-relative text-center mb-5 pb-2 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <h6 className="position-relative d-inline text-primary ps-4">
                  Contact Us
                </h6>
                <h2 className="mt-2">Contact For Any Query</h2>
                <p>Email: info@furahatechnologies.com</p>
                <p>Phone: +256 755 876951</p>
              </div>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
