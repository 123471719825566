import React from "react";
import Navbar from "./Navbar";
import Offcanvas from "./Offcanvas";
import { Link, NavLink } from "react-router-dom";

function Header() {
  return (
    <>
      <div className="container-fluid bg-secondary py-2 d-none d-md-flex">
        <div className="container">
          <div className="d-flex justify-content-between topbar">
            <div className="top-info">
              <a href="tel:+256 762 525 603">
                <small className="me-3 text-light">
                  <i className="fas fa-map-marker-alt me-2 text-warning"></i>
                  +256 762 525 603 / +256 753 567 803{" "}
                </small>
              </a>
              <a href="mailto:info@furahatechnologies.com">
                <small className="me-3 text-light">
                  <i className="fas fa-envelope me-2 text-warning"></i>
                  info@furahatechnologies.com
                </small>
              </a>
            </div>
            <div className="top-link">
              <a
                href=""
                className="bg-light nav-fill btn btn-sm-square rounded-circle"
              >
                <i className="fab fa-facebook-f text-primary"></i>
              </a>
              <a
                href=""
                className="bg-light nav-fill btn btn-sm-square rounded-circle"
              >
                <i className="fab fa-twitter text-primary"></i>
              </a>
              <a
                href=""
                className="bg-light nav-fill btn btn-sm-square rounded-circle"
              >
                <i className="fab fa-instagram text-primary"></i>
              </a>
              <a
                href=""
                className="bg-light nav-fill btn btn-sm-square rounded-circle me-0"
              >
                <i className="fab fa-linkedin-in text-primary"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-white">
        <div className="container">
          <nav className="navbar navbar-dark navbar-expand-lg py-0">
            <a href="index.html" className="navbar-brand">
              <img className="fw-bold d-block" src="img/logo.png" />
            </a>
            <button
              type="button"
              className="navbar-toggler me-0"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="fa fa-bars text-secondary"></span>
            </button>
            <div
              className="collapse navbar-collapse bg-transparent"
              id="navbarCollapse"
            >
              <div className="navbar-nav ms-auto mx-xl-auto p-0">
                <Link
                  to="/"
                  className="nav-item nav-link active text-secondary"
                >
                  Home
                </Link>
                <Link to="/about" className="nav-item nav-link">
                  About
                </Link>
                {/* <Link to="/features" className="nav-item nav-link">
                  Features
                </Link>
                <Link to="/pricing" className="nav-item nav-link">
                  Pricing
                </Link> */}
                <Link to="/developers" className="nav-item nav-link">
                  Developers
                </Link>
                {/* <Link to="/blog" className="nav-item nav-link">
                  Blog
                </Link> */}
                <Link to="/contact" className="nav-item nav-link">
                  Contact
                </Link>
              </div>
            </div>
            <div className="d-none d-xl-flex flex-shirink-0">
              <div
                id="phone-tada"
                className="d-flex align-items-center justify-content-center me-4"
              >
                <a href="" className="position-relative animated tada infinite">
                  <i className="fa fa-phone-alt text-secondary fa-2x"></i>
                  <div
                    className="position-absolute"
                    style={{ top: "-7p", left: "20px" }}
                  >
                    <span>
                      <i className="fa fa-comment-dots text-primary"></i>
                    </span>
                  </div>
                </a>
              </div>
              <div className="d-flex flex-column pe-4">
                <span className="text-secondary-50">Have any questions?</span>
                <span className="text-secondary">Call: +256 762 525 603</span>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Header;
