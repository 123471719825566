import React, { useEffect } from 'react';
import { Link, NavLink, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import "./styles.css";
import Home from './views/Home';
import Header from './components/Header';
import About from './views/About';
import Footer from './components/Footer';
import Services from './views/Services';
import Contact from './views/Contact';
import Pricing from './views/Pricing';
import Developer from './views/Developer';
import Page404 from './views/404';

function App () {
  const { pathname } = useLocation();

  // Scroll to top if path changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Header />
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/services" element={<Services/>}/>
        <Route path="/pricing" element={<Pricing/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/developers" element={<Developer/>}/>
        <Route path="*" element={<Page404/>}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
