import { Link } from "react-router-dom";

function HomeService() {
  return (
    <div className="container-fluid services py-5 mb-5">
            <div className="container">
                <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: '600px'}}>
                    <h5 className="text-primary">Our Main Features</h5>
                    <h1>Features that cater for all your messaging needs</h1>
                </div>
                <div className="row g-5 services-inner">
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
                        <div className="services-item bg-light">
                            <div className="p-4 text-center services-content">
                                <div className="services-content-icon">
                                    <i className="fa fa-sms fa-7x mb-4 text-primary"></i>
                                    <h4 className="mb-3">Bulk SMS</h4>
                                    <p className="mb-4">Send mass text messages effortlessly to your audience. Ideal for businesses looking to engage with customers on promotions, notifications, and alerts.</p>
                                    <a href="" className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
                        <div className="services-item bg-light">
                            <div className="p-4 text-center services-content">
                                <div className="services-content-icon">
                                    <i className="fa fa-code fa-7x mb-4 text-primary"></i>
                                    <h4 className="mb-3"> API Integration</h4>
                                    <p className="mb-4">Integrate Furaha SMS capabilities directly into your existing software or CRM system. Our robust API ensures seamless sending and receiving of SMS.</p>
                                    <a href="" className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
                        <div className="services-item bg-light">
                            <div className="p-4 text-center services-content">
                                <div className="services-content-icon">
                                    <i className="fa fa-globe-africa fa-7x mb-4 text-primary"></i>
                                    <h4 className="mb-3">International SMS</h4>
                                    <p className="mb-4">Extend your reach beyond borders. We support international SMS to various African countries including South Africa, Nigeria, Ghana, Botswana, Benin, Tanzania, Kenya, Rwanda, and Zambia.</p>
                                    <a href="" className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
                        <div className="services-item bg-light">
                            <div className="p-4 text-center services-content">
                                <div className="services-content-icon">
                                    <i className="fas fa-phone-alt fa-7x mb-4 text-primary"></i>
                                    <h4 className="mb-3">Voice Calls</h4>
                                    <p className="mb-4">Not just text—our platform allows you to engage with your audience through voice calls. Perfect for more personalized interactions.</p>
                                    <a href="" className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</a>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
                        <div className="services-item bg-light">
                            <div className="p-4 text-center services-content">
                                <div className="services-content-icon">
                                    <i className="fa fa-envelope-open fa-7x mb-4 text-primary"></i>
                                    <h4 className="mb-3">2-Way SMS & USSD</h4>
                                    <p className="mb-4">Enable conversations with your audience through two-way SMS and USSD capabilities. This feature lets you receive responses to your messages, creating a dynamic two-way communication channel.</p>
                                    <a href="" className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
                        <div className="services-item bg-light">
                            <div className="p-4 text-center services-content">
                                <div className="services-content-icon">
                                    <i className="fas fa-shield-alt fa-7x mb-4 text-primary"></i>
                                    <h4 className="mb-3">Enhanced Security</h4>
                                    <p className="mb-4">Prioritize the privacy and security of your communications with our end-to-end encryption and secure data storage solutions. Rest assured, your messages are safe with us.</p>
                                    <a href="" className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
}

export default HomeService;
