import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import WebPageTitle from "../components/WebPageTitle";
import Cta from "../components/Cta";

function Pricing() {
  return (
    <div>
      {/* <WebPageTitle title="Pricing" />
      <Breadcrumb pageTitle="Pricing" />


      <Cta /> */}
    </div>
  );
}

export default Pricing;
