import { Link } from "react-router-dom";

function HomeAbout() {
  return (
    <div className="container-fluid py-5 my-5">
      <div className="container pt-5">
        <div className="row g-5">
          <div
            className="col-lg-6 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".3s"
          >
            <div className="h-100 position-relative">
              <img
                src="img/about-1.jpg"
                className="img-fluid w-75 rounded"
                alt=""
                style={{ marginBottom: "25%" }}
              />
              <div
                className="position-absolute w-75"
                style={{
                  top: "5%",
                  left: "5%",
                  boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                <img
                  src="img/coverage.jpeg"
                  className="img-fluid w-100 rounded"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".5s"
          >
            <h5 className="text-primary">About Us</h5>
            <h1 className="mb-4">
            Revolutionize the way you interact with your audience.
            </h1>
            <p>
              Born in the heart of Uganda, Furaha SMS is more than just an SMS
              platform. We believe in the power of communication and its ability
              to transform businesses. Our mission? To provide reliable, fast,
              and affordable messaging solutions that empower every African
              business, big or small.
            </p>
            <p className="mb-4">
              With over 10 countries supported in Africa, Furaha Sms provides a
              versatilee,reliable, and easy to use SMS platform that empowers
              businesses to reach stakeholders whenever they need to, wherever
              they are!
            </p>
            <Link
              to="/about"
              className="btn btn-secondary rounded-pill px-5 py-3 text-white"
            >
              More Details
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeAbout;
