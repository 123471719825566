function Cta() {
  const styles = {
    width: "50px",
    height: "50px",
  };

  return (
    <div className="container-fluid py-5">
      <div className="container pt-5">
        <div className="row g-5">
          <div
            className="services-item bg-light p-5 rounded wow fadeIn"
            data-wow-delay=".3s"
          >
            <div
              className="row justify-content-center"
              style={{ height: 250 + "px" }}
            >
              <div className="col-8 text-center">
                <h1 className="text-secondary">
                  Ready to Elevate Your Business with Furaha SMS?
                </h1>
                <p>
                  Why wait? Unlock the full potential of SMS for your business
                  with Furaha SMS. Whether you're a small business or a large
                  enterprise, our platform has features tailored to your needs.
                  Take the first step in transforming your communications
                  strategy today.
                </p>
                <div className="mt-3">
                  <a target="_blank" href="https://app.furahasms.com" className="btn btn-secondary rounded-pill px-5 py-3 text-white">
                    Get Started Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cta;
