import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ pageTitle }) => {
    return (
        <div class="container-fluid page-header py-5">
            <div class="container text-center py-5">
                <h1 class="display-2 text-white mb-4 animated slideInDown">{pageTitle}</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb justify-content-center mb-0 animated slideInDown">
                        <li class="breadcrumb-item"><Link className="text-primary" to="/">Home</Link></li>
                        <li class="breadcrumb-item text-white" aria-current="page">{pageTitle}</li>
                    </ol>
                </nav>
            </div>
        </div>
    );
}

export default Breadcrumb;