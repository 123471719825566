import React, { useEffect } from "react";
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import $ from 'jquery'; // Import jQuery (required for Owl Carousel)

function Testimonials() {
  return (
    <div className="container-fluid testimonial py-5">
      <div className="container py-5">
        <div className="text-center mb-5 wow fadeInUp" data-wow-delay=".3s">
          <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
            Testimonial
          </h5>
          <h1 className="display-5 w-50 mx-auto">
            What Clients Say About Our Services
          </h1>
        </div>
        <div className="testimonial-carousel wow fadeInUp" data-wow-delay=".5s">
          <div className="row g-5">
            <div
              className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp"
              data-wow-delay=".3s"
              style={{
                visibility: "visible",
                animationDelay: "0.3s",
                animationName: "fadeInUp",
              }}
            >
              <div className="bg-light rounded p-1 services-item">
                <div class="card-body">
                  <h4 class="card-title">
                    <img src="img/quote.png" />
                  </h4>

                  <div class="template-demo">
                    <p>
                      Furaha SMS has been a game-changer for our business. The
                      API was easy to integrate, and now we can reach all our
                      customers with the click of a button
                    </p>
                  </div>

                  <hr />

                  <div class="row">
                    <div class="col-sm-2">
                      <img
                        class="profile-pic"
                        src="https://img.icons8.com/bubbles/100/000000/edit-user.png"
                      />
                    </div>

                    <div class="col-sm-10">
                      <div class="profile">
                        <h4 class="cust-name">Mukisa Isaac Newton</h4>
                        <p class="cust-profession">Software Developer</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp"
              data-wow-delay=".3s"
              style={{
                visibility: "visible",
                animationDelay: "0.3s",
                animationName: "fadeInUp",
              }}
            >
              <div className="bg-light rounded p-1 services-item">
                <div class="card-body">
                  <h4 class="card-title">
                    <img src="img/quote.png" />
                  </h4>

                  <div class="template-demo">
                    <p>
                    We were looking for a cost-effective solution for customer engagement. Furaha SMS delivered just that! Their rates are incredibly affordable, and their platform is so easy to use.
                    </p>
                  </div>

                  <hr />

                  <div class="row">
                    <div class="col-sm-2">
                      <img
                        class="profile-pic"
                        src="https://img.icons8.com/bubbles/100/000000/edit-user.png"
                      />
                    </div>

                    <div class="col-sm-10">
                      <div class="profile">
                        <h4 class="cust-name">Nkwakwo Julius</h4>
                        <p class="cust-profession">Ecommerce startup</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp"
              data-wow-delay=".3s"
              style={{
                visibility: "visible",
                animationDelay: "0.3s",
                animationName: "fadeInUp",
              }}
            >
              <div className="bg-light rounded p-1 services-item">
                <div class="card-body">
                  <h4 class="card-title">
                    <img src="img/quote.png" />
                  </h4>

                  <div class="template-demo">
                    <p>
                    Security was a big concern for us, and Furaha SMS assured us with their robust security features. We can now communicate sensitive information without any worries.
                    </p>
                  </div>

                  <hr />

                  <div class="row">
                    <div class="col-sm-2">
                      <img
                        class="profile-pic"
                        src="https://img.icons8.com/bubbles/100/000000/edit-user.png"
                      />
                    </div>

                    <div class="col-sm-10">
                      <div class="profile">
                        <h4 class="cust-name">Tsambala Vianey</h4>
                        <p class="cust-profession">Business person</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
