import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import WebPageTitle from "../components/WebPageTitle";
import HomeAbout from "../components/HomeAbout";
import Cta from "../components/Cta";

function About() {
  return (
    <div>
      <WebPageTitle title="About us" /> {/*page title */}
      <Breadcrumb pageTitle="About us" />
      <div className="container-fluid my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div
              className="col-lg-6 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <div className="h-100 position-relative">
                <img
                  src="img/about-1.jpg"
                  className="img-fluid w-75 rounded"
                  alt=""
                  style={{ marginBottom: "25%" }}
                />
                <div
                  className="position-absolute w-75"
                  style={{
                    top: "5%",
                    left: "5%",
                    boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <img
                    src="img/coverage.jpeg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <h5 className="text-primary">About Us</h5>
              <h1 className="mb-4">
                Revolutionize the way you interact with your audience.
              </h1>
              <p>
                Born in the heart of Uganda, Furaha SMS is more than just an SMS
                platform. We believe in the power of communication and its
                ability to transform businesses. Our mission? To provide
                reliable, fast, and affordable messaging solutions that empower
                every African business, big or small.
              </p>
              <p className="mb-4">
                With over 10 countries supported in Africa, Furaha Sms provides
                a versatilee,reliable, and easy to use SMS platform that
                empowers businesses to reach stakeholders whenever they need to,
                wherever they are!
              </p>
              <a
                href="https://furahatechnologies.com/about"
                target="_blank"
                className="btn btn-secondary rounded-pill px-5 py-3 text-white"
              >
                More Details
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid services">
        <div className="container py-5">
          <h1 className="mb-4">Frequently Asked Questions</h1>
          <div className="row g-5">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button btn-primary"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    1. How do I get started with Furaha SMS?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Getting started is easy. Just sign up for an account, add
                    your contacts, and you're ready to send your first SMS!
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button btn-primary"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    What countries do you support for international messaging?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    We currently support multiple African countries including
                    South Africa, Nigeria, Ghana, Botswana, Benin, Tanzania,
                    Kenya, Rwanda, and Zambia.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button btn-primary"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Is API Integration available?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Yes, we offer robust API integration for businesses that
                    wish to send SMS messages directly from their existing
                    platforms.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button btn-primary"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    How much does it cost to send an SMS?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Our SMS rates start as low as UGX 35 per message. For more
                    information, visit our Pricing page.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button btn-primary"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    What is 2-Way SMS & USSD?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Our 2-Way SMS & USSD feature allows you to not just send
                    messages but also receive responses from your audience,
                    making for interactive and dynamic communication.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button btn-primary"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    How secure is Furaha SMS?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Security is one of our top priorities. We offer end-to-end
                    encryption and secure data storage to ensure the privacy and
                    integrity of your messages.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button btn-primary"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    Who can benefit from using Furaha SMS?
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Furaha SMS is ideal for businesses, non-profit
                    organizations, educational institutions, and anyone else who
                    needs to send SMS messages on a large scale.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Cta />
    </div>
  );
}

export default About;
