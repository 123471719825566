import React from "react";
import WebPageTitle from "../components/WebPageTitle";
import Breadcrumb from "../components/Breadcrumb";

function Developer() {
  return (
    <div>
      <WebPageTitle title="API Documentation" />
      <Breadcrumb pageTitle="Developer" />
      <div className="container-fluid bg-secondary">
        <div className="container py-5">
          <h1 className="text-primary">Furaha SMS API Usage Guide</h1>
          <p className="text-light">Welcome to the Furaha Send SMS API...</p>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container px-lg-5">
          <div className="row g-4">
            <div className="container mt-5">
              <section id="getting-started" className="mb-5">
                <h2>Getting Started</h2>
                <p>This guide will help you get started...</p>

                <h4>URL</h4>
                <code>https://api.furahasms.com/v1/..</code>

                <h4>Request Type</h4>
                <p>The request types are POST and GET</p>
                <h4>Parameters</h4>
                <p>
                  These are separated using the “&” sign just like in a normal
                  http get.
                </p>
                <h4>Required</h4>
                <p>
                  <strong>Number</strong>: This represents the URL encoded phone
                  number.
                </p>
                <p>
                  <strong>Message</strong>: This represents the URL encoded SMS
                  you are sending out. The message source Must have a maximum of
                  160 characters.
                </p>
                <p>
                  <strong>Sender</strong>: This represents who the message is
                  coming from. Sender can have a maximum of 11 Characters and
                  needs to be URL encoded too.
                </p>
                <p>
                  <strong>Username</strong>: The API callers username used to
                  login to Pandora SMS.
                </p>
                <p>
                  <strong>Password</strong>: The API callers password used to
                  login to Pandora SMS
                </p>
                <p>
                  <strong>Message Type</strong>: This represents the sms type
                  i.e non_customised, customised or info.
                </p>
                <p>
                  <strong>Message category</strong>: This represents the sms
                  category i.e bulk or group.
                </p>

                <h4>Success Response</h4>
                <p>
                  If your request is success, a JSON object is retirned as
                  follows
                  <br />
                  <code>
                    {'{"success": true, "message": "Your success message"}'}
                  </code>
                </p>

                <h4>Error Response</h4>
                <p>
                  If your request is not success, a JSON object is retirned as
                  follows
                  <br />
                  <code>
                    {
                      '{"success": false, "error_message": "Your success message"}'
                    }
                  </code>
                </p>
                <h4 className="text--danger">Note</h4>
                <p>Send to Airtel and MTN contacts only</p>
              </section>

              <section id="endpoints" className="mb-5">
                <h2>Example</h2>

                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-bs-toggle="tab"
                      href="#php"
                    >
                      PHP
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#javascript"
                    >
                      JavaScript
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#python">
                      Python
                    </a>
                  </li>
                </ul>

                <div className="tab-content mt-3">
                  <div className="tab-pane active" id="php">
                    <ul className="nav nav-tabs col-12 mt-5">
                      <li className="nav-item col-6">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#phpGET"
                        >
                          GET
                        </a>
                      </li>
                      <li className="nav-item col-6">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#phpPOST"
                        >
                          POST
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content mt-3">
                      <div className="tab-pane active" id="phpGET">
                        <pre>
                          <code>
                            {`$curl = curl_init();
                                curl_setopt_array($curl, array(
                                  CURLOPT_URL => 'https://api.furahasms.com//api/send_sms?username=29479314&api_key=1EunqdciOYgJGIMi56UzJAEsT7f0clYM&numbers=0755876951%2C%200776278284&message=Hello%20World&type=info',
                                  CURLOPT_RETURNTRANSFER => true,
                                  CURLOPT_ENCODING => '',
                                  CURLOPT_MAXREDIRS => 10,
                                  CURLOPT_TIMEOUT => 0,
                                  CURLOPT_FOLLOWLOCATION => true,
                                  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
                                  CURLOPT_CUSTOMREQUEST => 'GET',
                                ));

                                $response = curl_exec($curl);

                                curl_close($curl);
                                echo $response;`}
                          </code>
                        </pre>
                      </div>
                      <div className="tab-pane" id="phpPOST">
                        <pre>
                          <code>
                            {`$curl = curl_init();
                              curl_setopt_array($curl, array(
                                CURLOPT_URL => 'https://api.furahasms.com//api/send_sms',
                                CURLOPT_RETURNTRANSFER => true,
                                CURLOPT_ENCODING => '',
                                CURLOPT_MAXREDIRS => 10,
                                CURLOPT_TIMEOUT => 0,
                                CURLOPT_FOLLOWLOCATION => true,
                                CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
                                CURLOPT_CUSTOMREQUEST => 'POST',
                                CURLOPT_POSTFIELDS =>'{
                                  "username": "29479314",
                                  "api_key": "1EunqdciOYgJGIMi56UzJAEsT7f0clYM",
                                  "type": "info",
                                  "numbers": "256755876951,0776278284",
                                  "message": "Hello World"
                              }',
                                CURLOPT_HTTPHEADER => array(
                                  'Content-Type: application/json'
                                ),
                              ));

                              $response = curl_exec($curl);

                              curl_close($curl);
                              echo $response;`}
                          </code>
                        </pre>
                      </div>
                    </div>
                  </div>

                  {/* Similar nested tabs for JavaScript */}
                  <div className="tab-pane" id="javascript">
                    <ul className="nav nav-tabs mt-5">
                      <li className="nav-item col-6">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#jsGET"
                        >
                          GET
                        </a>
                      </li>
                      <li className="nav-item col-6">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#jsPOST"
                        >
                          POST
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content mt-3">
                      <div className="tab-pane active" id="jsGET">
                        <pre>
                          <code>Coming soon</code>
                        </pre>
                      </div>
                      <div className="tab-pane" id="jsPOST">
                        <pre>
                          <code>Coming soon</code>
                        </pre>
                      </div>
                    </div>
                  </div>

                  {/* Similar nested tabs for Python */}
                  <div className="tab-pane" id="python">
                    <ul className="nav nav-tabs mt-5">
                      <li className="nav-item col-6">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#pyGET"
                        >
                          GET
                        </a>
                      </li>
                      <li className="nav-item col-6">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#pyPOST"
                        >
                          POST
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content mt-3">
                      <div className="tab-pane active" id="pyGET">
                        <pre>
                          <code>Coming soon</code>
                        </pre>
                      </div>
                      <div className="tab-pane" id="pyPOST">
                        <pre>
                          <code>Coming soon</code>
                        </pre>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Developer;
