import React from "react";
import { Link } from "react-router-dom";

const Page404 = ({ pageTitle }) => {
    return (
        <div class="container-fluid page-header py-5">
            <div class="container text-center py-5 my-5">
                <h1 class="display-2 text-white mb-4 animated slideInDown">Page not ready yet</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb justify-content-center mb-0 animated slideInDown">
                        <li class="breadcrumb-item"><Link className="text-primary" to="/">Home</Link></li>
                        <li class="breadcrumb-item text-white" aria-current="page">404</li>
                    </ol>
                </nav>
                <Link to="/" className="btn btn-secondary rounded-pill px-5 py-3 text-white mt-5">Go back home</Link>
            </div>
        </div>
    );
}

export default Page404;