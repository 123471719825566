import React, { useEffect, useState } from "react";
import HomeAbout from "../components/HomeAbout";
import HomeService from "../components/HomeService";
import Cta from "../components/Cta";
import WebPageTitle from "../components/WebPageTitle";
import Testimonials from "../components/Testimonials";
import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <WebPageTitle title={"Home"} />
      <div className="container-fluid px-0">
        <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner" role="listbox">
            <div className="carousel-item active">
              <img
                src="img/carousel-2.jpg"
                className="img-fluid w-100"
                alt="First slide"
              />
              <div className="carousel-caption">
                <div className="carousel-content py-sm-2">
                  <h6 className="text-primary h4 animated fadeInUp">
                    Furaha SMS
                  </h6>
                  <h1 className="text-white display-1 mb-4 animated fadeInRight">
                    Empowering Businesses with Seamless SMS Solutions
                  </h1>
                  <p className="mb-4 text-white fs-5 animated fadeInDown">
                    Effortlessly connect, communicate, and grow with Furaha SMS
                    – your trusted partner for comprehensive messaging services.
                  </p>
                  <a
                    href="https://app.furahasms.com/"
                    target="_blank"
                    className="me-2"
                  >
                    <button
                      type="button"
                      className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft"
                    >
                      Start now
                    </button>
                  </a>
                  <Link
                    to="/about"
                    className="ms-2"
                  >
                    <button
                      type="button"
                      className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight"
                    >
                      About us
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-secondary py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 wow fadeIn" data-wow-delay=".1s">
              <div className="d-flex counter">
                <h1 className="me-3 text-primary counter-value">10</h1>
                <h5 className="text-white mt-1">
                  Countries supported in Africa
                </h5>
              </div>
            </div>
            <div className="col-lg-3 wow fadeIn" data-wow-delay=".3s">
              <div className="d-flex counter">
                <h1 className="me-3 text-primary counter-value">367</h1>
                <h5 className="text-white mt-1">Happy busineses supported</h5>
              </div>
            </div>
            <div className="col-lg-3 wow fadeIn" data-wow-delay=".5s">
              <div className="d-flex counter">
                <h1 className="me-3 text-primary counter-value">133</h1>
                <h5 className="text-white mt-1">
                  Developers using Furaha Sms api
                </h5>
              </div>
            </div>
            <div className="col-lg-3 wow fadeIn" data-wow-delay=".7s">
              <div className="d-flex counter">
                <h1 className="me-3 text-primary counter-value">2300+</h1>
                <h5 className="text-white mt-1">
                  Daily requests
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <HomeAbout />

      <HomeService />

      <Testimonials />

      <Cta />
    </>
  );
}

export default Home;
