import { Link } from "react-router-dom";

function Footer() {
  const currentDate = new Date().getFullYear();

  return (
    <div
      className="container-fluid footer bg-secondary wow fadeIn"
      data-wow-delay=".3s"
    >
      <div className="container pt-5 pb-4">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <Link to="/">
              <img className="fw-bold d-block" src="img/logo-white.png" />
            </Link>
            <p className="mt-4 text-light">
              Furaha SMS is your all-in-one platform for seamless SMS-based
              communication. With features like Bulk SMS, API Integration, and
              international messaging, we help businesses break down
              communication barriers.
            </p>
            <div className="d-flex hightech-link">
              <a
                href=""
                className="btn-light nav-fill btn btn-square rounded-circle me-2"
              >
                <i className="fab fa-facebook-f text-primary"></i>
              </a>
              <a
                href=""
                className="btn-light nav-fill btn btn-square rounded-circle me-2"
              >
                <i className="fab fa-twitter text-primary"></i>
              </a>
              <a
                href=""
                className="btn-light nav-fill btn btn-square rounded-circle me-2"
              >
                <i className="fab fa-instagram text-primary"></i>
              </a>
              <a
                href=""
                className="btn-light nav-fill btn btn-square rounded-circle me-0"
              >
                <i className="fab fa-linkedin-in text-primary"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <a href="#" className="h3 text-primary">
              Short Link
            </a>
            <div className="mt-4 d-flex flex-column short-link">
              <Link to="/about" className="mb-2 text-white">
                <i className="fas fa-angle-right text-light me-2"></i>About us
              </Link>
              <Link to="/contact" className="mb-2 text-white">
                <i className="fas fa-angle-right text-light me-2"></i>Contact us
              </Link>
              <Link to="" className="mb-2 text-white">
                <i className="fas fa-angle-right text-light me-2"></i>Features
              </Link>
              <Link to="/pricing" className="mb-2 text-white">
                <i className="fas fa-angle-right text-light me-2"></i>Pricing
              </Link>
              <Link to="/blog" className="mb-2 text-white">
                <i className="fas fa-angle-right text-light me-2"></i>Latest
                Blog
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <a href="" className="h3 text-primary">
              Help Link
            </a>
            <div className="mt-4 d-flex flex-column help-link">
              <a href="" className="mb-2 text-white">
                <i className="fas fa-angle-right text-light me-2"></i>Terms Of
                use
              </a>
              <a href="" className="mb-2 text-white">
                <i className="fas fa-angle-right text-light me-2"></i>Privacy
                Policy
              </a>
              <a href="" className="mb-2 text-white">
                <i className="fas fa-angle-right text-light me-2"></i>FQAs
              </a>
              <Link to="/contact" className="mb-2 text-white">
                <i className="fas fa-angle-right text-light me-2"></i>Contact
              </Link>
              <Link to="/developer" className="mb-2 text-white">
                <i className="fas fa-angle-right text-light me-2"></i>Developer
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <a href="" className="h3 text-primary">
              Contact Us
            </a>
            <div className="text-white mt-4 d-flex flex-column contact-link">
              <a
                href="#"
                className="pb-3 text-light border-bottom border-primary"
              >
                <i className="fas fa-map-marker-alt text-primary me-2"></i> Arua
                City Opp. Arua Hill Stadium
              </a>
              <a
                href="tel:+256 755 876951"
                className="py-3 text-light border-bottom border-primary"
              >
                <i className="fas fa-phone-alt text-primary me-2"></i>{" "}
                +256762525603 <br />{" "}
                <i className="fas fa-phone text-primary me-2"></i>
                +256753567803
              </a>
              <a
                href="mailto:info@furahatechnologies.com"
                className="py-3 text-light border-bottom border-primary"
              >
                <i className="fas fa-envelope text-primary me-2"></i>{" "}
                info@furahatechnologies.com
              </a>
            </div>
          </div>
        </div>
        <hr className="text-light mt-5 mb-4" />
        <div className="row">
          <div className="col-md-6 text-center text-md-start">
            <span className="text-light">
              A product of{" "}
              <a
                href="https://furahatechnologies.com"
                target="_blank"
                className="text-light"
              >
                <i className="fas fa-copyright text-light me-2"></i>Furaha
                Technologies
              </a>
            </span>
          </div>
          <div className="col-md-6 text-center text-md-end">
            <span className="text-light">
              All rights reserved &copy; Furaha SMS {new Date().getFullYear()}.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
