import React, { useRef, useState } from 'react';
import emailjs from "@emailjs/browser";

function ContactForm() {
  const form = useRef();
  const [message, setMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_295vu7g',
        'template_qhiedl7',
        form.current,
        'q0OoDk_GldMJ60iIY'
      )
      .then(
        (result) => {
          setMessage('Email successfully sent!');
          form.current.reset();
        },
        (error) => {
          setMessage('Failed to send email. Please try again later.');
        }
      );
  };

  return (
    <div className="wow fadeInUp" data-wow-delay="0.3s">
      <form ref={form} onSubmit={sendEmail}>
        <div className="row g-3">
          <div className="col-md-6">
            <div className="form-floating">
              <input required type="text" className="form-control" name="user_name" placeholder="Your Name" />
              <label htmlhtmlFor="name">Your Name</label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-floating">
              <input required type="email" className="form-control" name="email" placeholder="Your Email" />
              <label htmlhtmlFor="email">Your Email</label>
            </div>
          </div>
          <div className="col-12">
            <div className="form-floating">
              <input required type="text" className="form-control" name="subject" placeholder="Subject" />
              <label htmlhtmlFor="subject">Subject</label>
            </div>
          </div>
          <div className="col-12">
            <div className="form-floating">
              <textarea
                required
                className="form-control"
                placeholder="Leave a message here"
                name="message"
                style={{ height: '150px' }}
              ></textarea>
              <label htmlhtmlFor="message">Message</label>
            </div>
          </div>
          <div className="col-12">
            <button className="btn btn-primary border-0 rounded-pill px-4 py-3 mt-5 w-100" type="submit">
              Send Message
            </button>
          </div>
          {message && <div className="col-12 mt-3"><p>{message}</p></div>}
        </div>
      </form>
    </div>
  );
}

export default ContactForm;
